import React, { Component } from "react"

export default class Comments extends Component {
  constructor(props) {
    super(props)
    this.commentBox = React.createRef() // Creates a reference to inject the <script> element
  }
  componentDidMount() {
    let scriptEl = document.createElement("script")
    scriptEl.setAttribute("src", "https://utteranc.es/client.js")
    scriptEl.setAttribute("crossorigin", "anonymous")
    scriptEl.setAttribute("async", true)
    scriptEl.setAttribute("repo", "pilefort/pilefort.blog.comments")
    scriptEl.setAttribute("issue-term", "title")
    scriptEl.setAttribute("theme", "github-light")
    this.commentBox.current.appendChild(scriptEl)
  }

  render() {
    return (
      <div className="">
        <h1 className="mb-0">コメント</h1>
        <div
          style={{
            display: `flex`,
            justifyContent: `left`,
          }}
        >
          <div className="comments" ref={this.commentBox} />
        </div>
      </div>
    )
  }
}
