import React from "react"

const TOC = ({ tableOfContents }) => {
		const toc = []
		tableOfContents.items.forEach((item) => {
				toc.push({title: item.title, url: item.url})
				if (item.items) {
					item.items.forEach((childItem) => {
							toc.push({title: childItem.title, url: childItem.url})
					})
				}
		})

		return toc.map((item, index) => {
				return (
						<div key={index} className="index">
								<a href={item.url}>{item.title}</a>
						</div>
				)
		})
}

export default TOC
