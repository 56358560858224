import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Toc from "../components/toc"
import Comments from "../components/comments"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx
  const { previous, next } = data

  return (
    <Layout location={location} title={post.frontmatter.title} blogTitle={post.frontmatter.title}>
      <Seo title={post.frontmatter.title} description={post.frontmatter.description}/>
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <div className="container">
          <div className="mainContents">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
          <div className="subContents">
            <div className="subContentsMain">
              <Toc tableOfContents={post.tableOfContents} />
            </div>
          </div>
        </div>
      </article>
      <hr />
      <Comments />
      <footer />

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    mdx(id: { eq: $id }) {
      id
      body
      tableOfContents
      frontmatter {
        title
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
